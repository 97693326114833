<template>
	<div class="gg-container">
		
		<div class="search-container" >
			<!--对应第三方图标的button样式-->
		
				<div class="search-container-fn-input" style="margin-right: 50px">
				<el-button
					size="mini"
					type="primary"
					style="padding: 6px 7px 7px 8px; background-color: #2362FB; border-color: #2362FB"
					@click="handlerBar">
					<i class="iconfont el-icon-s-data" style="font-size: 13px"/>
					<span style="margin-left: 5px; font-size: 12px">柱状图</span>
				</el-button>
			</div>
			
			
			<div class="search-container-fn-input" style="margin-right: 50px">
				<el-button
					size="mini"
					type="primary"
					style="padding: 6px 7px 7px 8px; background-color: #2362FB; border-color: #2362FB"
					@click="handlerLine">
					<i class="iconfont el-icon-data-line" style="font-size: 13px"/>
					<span style="margin-left: 5px; font-size: 12px">折线图</span>
				</el-button>
			</div>
			
			<div class="search-container-fn-input" style="margin-right: 50px">
				<el-button
					size="mini"
					type="primary"
					style="padding: 6px 7px 7px 8px; background-color: #2362FB; border-color: #2362FB"
					@click="handlerPie">
					<i class="iconfont el-icon-pie-chart" style="font-size: 13px"/>
					<span style="margin-left: 5px; font-size: 12px">饼图</span>
				</el-button>
			</div>
			
			
			<div class="search-container-fn-input" style="margin-right: 50px">
				<el-button
					size="mini"
					type="success"
					style="padding: 6px 7px 7px 8px;"
					@click="handlerExportPDF">
					<i class="iconfont el-icon-document" style="font-size: 13px"/>
					<span style="margin-left: 5px; font-size: 12px">导出PDF</span>
				</el-button>
			</div>
			
		</div>ar
	
	<div id="exportPdfBar" ref="exportPdfBar">
		<div ref="recordReportBar" class="record-report-bar"
				 :style="{height: scrollerHeightBar}"></div>
	</div>
		
		<div id="exportPdfLine" ref="exportPdfLine">
			<div ref="recordReportLine" class="record-report-line"
				 :style="{height: scrollerHeightLine}"></div>
		</div>
		
		<div id="exportPdfPie" ref="exportPdfPie">
		<div ref="recordReportPie" class="record-report-pie"
				:style="{height: scrollerHeightPie}"></div>
		</div>
		
		<div class="search-container">
			<div class="search-container-fn-input" style="margin-right: 50px">
				<el-button
					size="mini"
					type="primary"
					style="padding: 6px 7px 7px 8px; background-color: #2362FB; border-color: #2362FB"
					@click="handlerHideTable">
					<i class="iconfont el-icon-date" style="font-size: 13px"/>
					<span style="margin-left: 5px; font-size: 12px">显示隐藏table表</span>
				</el-button>
			</div>
		</div>
		
		<div  v-if="isShowTable">
		<div class="search-container">
			<div class="search-container-fn-input">
				<label>日期：</label>
				<el-date-picker
					v-model="choiceDate"
					type="daterange"
					size="mini"
					value-format="yyyy-MM-dd HH-mm-ss "
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					@change="handleChangeDate">
				</el-date-picker>
			</div>
			<!--对应第三方图标的button样式-->
			<div class="search-container-fn-input" style="margin-left: auto;"
					 v-if="operateList.indexOf('export') > -1"
			>
				<el-button
					size="mini"
					type="primary"
					style="padding: 6px 7px 7px 8px; background-color: #2362FB; border-color: #2362FB"
					@click="handleExport('媒体日报')">
					<i class="iconfont icon-daoru" style="font-size: 13px"/>
					<span style="margin-left: 5px; font-size: 12px">导出</span>
				</el-button>
			</div>
		</div>
		<!----------------表格---------------->
		<el-table
			ref="report-table"
			height="625"
			border
			v-loading="listLoading"
			:header-cell-style="{'text-align':'center'}"
			:data="tableData"
			@selection-change="handleSelectionChange"
			style="width: 100%;z-index:0">
			<el-table-column
				type="index"
				align="center"
				width="50">
			</el-table-column>
			<el-table-column type="selection" width="55" align="center" />
			<el-table-column
				prop="created_at"
				label="建档日期"
				align="center">
			</el-table-column>
			<el-table-column
				prop="from"
				label="客户来源"
				align="center">
			</el-table-column>
			<el-table-column
				prop="thread_count"
				label="进线建档"
				align="center">
			</el-table-column>
			<el-table-column
				prop="media_count"
				label="媒体进线"
				align="center">
			</el-table-column>
			<el-table-column
				prop="diff_contract"
				label="线档差异"
				align="center">
			</el-table-column>
			<el-table-column
				prop="throw_amount"
				label="投放金额"
				align="center">
			</el-table-column>
			<el-table-column
				prop="settle_thread_count"
				label="结算进线"
				align="center">
			</el-table-column>
			<el-table-column
				prop="fans_rate"
				label="加粉率"
				align="center">
			</el-table-column>
			<el-table-column
				prop="first_rate"
				label="转化率"
				align="center">
			</el-table-column>
			<el-table-column
				prop="line_avg"
				label="客单价"
				align="center">
			</el-table-column>
			<el-table-column
				prop="fans_count"
				label="加粉数"
				align="center">
			</el-table-column>
			<el-table-column
				prop="first_count"
				label="初购单数"
				align="center">
			</el-table-column>
			<el-table-column
				prop="first_amount"
				label="初购总额"
				align="center">
			</el-table-column>
			<el-table-column
				prop="line_cost"
				label="线均成本"
				align="center">
			</el-table-column>
		</el-table>
		<!----------------分页---------------->
		<!--<pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
								@pagination="getList"/>-->
		</div>
	</div>
</template>

<script>
import Pagination from '@/components/Pagination'
import {getTodayMediaReport} from '@/api/ggkq';
import XLSX from 'xlsx';
import FileSaver from 'file-saver'
import {mapGetters, mapState} from 'vuex';
export default {
  name: "todayMediaReport",
  watch:{	// 监视数据改变,重新绘制Chart
    barData: {
      handler(newValue, oldValue) {
        console.log(newValue)
        this.barSourceTitle = Array.from(new Set(this.barSourceTitle))
        this.barDataX = []
        this.barDataTotalY = []
        if (Object.keys(newValue).length) {
          newValue.forEach(item => {
            this.barDataX.push(item.created_at)
          })
        }
        this.barDataX = Array.from(new Set(this.barDataX))
			
	
				this.barDataX.forEach(time => {
          this.barTime[time] = []
          newValue.forEach(item => {
            if(item.created_at == time) {
              this.barTime[time].push(item)
						}
          })
				})
				
				var flag = false
				var tmp = ''
        Object.keys(this.barSource).forEach(key => {
          Object.keys(this.barTime).forEach(k => {
            tmp = ''
            flag = false
            this.barTime[k].forEach(item => {
              if(item.from == key) {
                flag = true
                tmp = item.throw_amount_real
              }
						})
            if(flag == true) {
              this.barSource[key].push(tmp)
            } else {
              this.barSource[key].push(0)
            }
					})
				})
				
				Object.keys(this.barSource).forEach(key => {
          var series = {
            name: '',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            barWidth: '50%', //柱图宽度
            data:  []
          }
          series.name = key
					series.data.push(...this.barSource[key])
					this.barSeries.push(series)
				})
        this.$nextTick(() => {
          this.chartInstanceBar.dispose()
          this._initChartBar()
        })
      },
      immediate: true,
      deep: true
    },

    lineData: {
      handler(newValue, oldValue) {
        console.log(newValue)
        this.lineDataX = []
        this.lineDataTotalY = []
        if (Object.keys(newValue).length) {
          newValue.forEach(item => {
            this.lineDataX.push(item.created_at)
            this.lineDataTotalY.push(item.throw_amount_real)
          })
          // newValue.emr.forEach(item => {
          //   this.barDataEmrY.push(item.count)
          // })
        }
        this.$nextTick(() => {
          this.chartInstanceLine.dispose()
          this._initChartLine()
        })
      },
      immediate: true,
      deep: true
    },

    PieDataOrigin: {
      handler(newValue, oldValue) {
        console.log(newValue)
        this.PieData = []
        if (Object.keys(newValue).length) {
          newValue.forEach(item => {
            var temp = {
              value : item.throw_amount_real,
              name: item.created_at
            }
            this.PieData.push(temp)
          })
          // newValue.emr.forEach(item => {
          //   this.barDataEmrY.push(item.count)
          // })
        }
        this.$nextTick(() => {
          this.chartInstancePie.dispose()
          this._initChartPie()
        })
      },
      immediate: true,
      deep: true
    },

  },
  mounted() {
    this._initChartBar()	//初始化Chart实例
    this._initChartLine()
    this._initChartPie()
    window.addEventListener("resize", this.chartInstanceBar.resize);	// 监听页面大小改变
    window.addEventListener("resize", this.chartInstanceLine.resize);	// 监听页面大小改变
    window.addEventListener("resize", this.chartInstancePie.resize);	// 监听页面大小改变
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.chartInstanceBar)	// 页面关闭时候移除监听
    window.removeEventListener('resize', this.chartInstanceLine)	// 页面关闭时候移除监听
    window.removeEventListener('resize', this.chartInstancePie)	// 页面关闭时候移除监听
  },
  components: {
    // Pagination,
  },
  async created () {
    console.log(this.$route.path)
    console.log(this.operates)
    this.operates.forEach(operate => {
      if(operate[this.$route.path]) {
        console.log(operate[this.$route.path].split(','))
        this.operateList.push(...operate[this.$route.path].split(','))
      }
    })
    await this.getList()
  },
  computed: {
    ...mapGetters(["agentId", "adminId"]),
    ...mapState({
      is_admin: state => state.user.is_admin,
      operates: state => state.user.operates,
    }),
  },
  data () {
    return {
      choiceDate: [],
      searchParams: {
        created_at: [],
      },
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: '+id',
      },
      total: 0,
      listLoading: false,
      options: [],
      tableData: [],
      operateList: [],

			
			curChart: 1,
			
			barSource: {}, // 客户来源Map
			barSourceTitle: [],
      barTime: {},
      barSeries: [],
      isShowTable:false,
      scrollerHeightBar: '484px',
      scrollerHeightLine: '0',
      scrollerHeightPie: '0',

      chartInstanceBar: null,
      barData: [],
      barDataOrigin: [],
      barDataX: [],	// 横轴数据
      barDataTotalY: [],	// 纵轴数据,投放金额数
      //  barDataPresY: [12, 23 ,56, 11, 9, 43],

      chartInstanceLine: null,
      lineData: [],
      lineDataOrigin: [],
      lineDataX: [],
      lineDataTotalY: [],


      chartInstancePie: null,
      PieDataOriginOld: [],
      PieDataOrigin: [],
      PieData: [],
    }
  },
  methods: {
    _initChartBar() {
      const initOption = {
        color: ['#5470c6','#91cc75','#fac858','#ee6666','#73c0de','#3ba272'],
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: 50,
          right: 30,
          bottom: 40,
        },
        legend: {
          data: [...this.barSourceTitle],
          itemWidth: 40,
          itemHeight: 20,
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
          },
          data: this.barDataX
        },
        yAxis: {
          type: 'value'
        },
        series: [...this.barSeries]
      }
      this.chartInstanceBar = this.$echarts.init(this.$refs.recordReportBar)	// 通过DOM获取Echart实例
      this.chartInstanceBar.showLoading()
      this.chartInstanceBar.setOption(initOption)	// 初始化数据
      this.chartInstanceBar.hideLoading();
    },

    _initChartLine() {
      const initOption = {
        color: ['#2362fb'],
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: 50,
          right: 30,
          bottom: 40,
        },
        legend: {
          data: ['投放金额'],
          itemWidth: 40,
          itemHeight: 20,
        },
        xAxis: {
          type: 'category',
          data: this.lineDataX
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: '投放金额',
          type: 'line',
          data:  this.lineDataTotalY
        }
        ]
      }
      this.chartInstanceLine = this.$echarts.init(this.$refs.recordReportLine)	// 通过DOM获取Echart实例
      this.chartInstanceLine.showLoading()
      this.chartInstanceLine.setOption(initOption)	// 初始化数据
      this.chartInstanceLine.hideLoading();
    },

    _initChartPie() {
      const initOption = {
        color: ['#5470c6', '#91cc75', '#fac858','#ee6666', '#73c0de', '#3ba272','#fc8452', '#9a60b4', '#ea7ccc'],
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'item'
        },

        grid: {
          left: 50,
          right: 30,
          bottom: 40,
        },
        legend: {
          orient: 'horizontal',
          left: 'center'
        },
        series: [{
          type: 'pie',
          radius: '75%',
          data:  this.PieData
        },],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
      this.chartInstancePie = this.$echarts.init(this.$refs.recordReportPie)	// 通过DOM获取Echart实例
      this.chartInstancePie.showLoading()
      this.chartInstancePie.setOption(initOption)	// 初始化数据
      this.chartInstancePie.hideLoading();
    },

    getList (type) {
      if (type == 'restPage') {
        this.listQuery.page = 1
      }
      this.listLoading = true
      // this.searchParams.page = this.listQuery.page
      // this.searchParams.page_size = this.listQuery.limit
      getTodayMediaReport(this.searchParams).then(response => {
        let data = response
        if (data.code == 200) {
          let _data = data.data
          this.barData =  JSON.parse(JSON.stringify(data.data.list))
          this.lineData = JSON.parse(JSON.stringify(data.data.list))
          this.PieDataOrigin = JSON.parse(JSON.stringify(data.data.list))
          // // this.total = _data.total
          // this.listQuery.limit = _data.per_page
          this.tableData = _data.list
					_data.list.forEach(item => {
            if (item.from == '') {
							item.from = '空白'
            }
					  this.barSourceTitle.push(item.from)
					  this.barSource[item.from] = []
					})
          // _data.total.staff_name = "汇总"
          this.tableData.unshift(..._data.total)
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.listLoading = false
      })
    },
    handleExport (excelName) {

      try {
        const $e = this.$refs['report-table'].$el
        let $table = $e.querySelector('.el-table__fixed')
        if(!$table) {
          $table = $e
        }

        const wb = XLSX.utils.table_to_book($table, {raw:true})
        const wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST:true, type: 'array'})
        FileSaver.saveAs(
          new Blob([wbout],{type: 'application/octet-stream'}),
          `${excelName}.xlsx`,
        )
      } catch (e) {
        if (typeof console !== 'undefined') console.error(e)
      }

    },
    handleChangeDate() {
      this.searchParams.start_at = []
      this.searchParams.end_at = []
      this.searchParams.created_at = ""
      console.log(this.choiceDate)
      var _tmp0
      var _tmp1
      if (this.choiceDate) {
        _tmp0 = this.choiceDate[0].substring(0,10)
        _tmp1 = this.choiceDate[1].substring(0,10)
        this.searchParams.created_at += _tmp0 + ","
        this.searchParams.created_at += _tmp1
      } else {

        this.searchParams.created_at = ""
      }
      this.getList('restPage')
    },
    handleSelectionChange(val) {
      console.log(val, "val");
      //去判断按钮的显示与隐藏

      this.barSource =  {} // 客户来源Map
      this.barSourceTitle = []
			this.barTime =  {}
      this.barSeries =  []
			
			
      this.multipleSelection = val;
      if(val.length == 0) {
        this.getList()
      } else {
        this.barData =  JSON.parse(JSON.stringify(val))
        this.barData.forEach(item => {
          if (item.from == '') {
            item.from = '空白'
          }
          this.barSourceTitle.push(item.from)
          this.barSourceTitle.push(item.from)
          this.barSource[item.from] = []
        })
        
        this.barData = val;
        this.lineData = val;
        this.PieDataOrigin = val;
      }

    },
    handlerBar() {
      this.curChart = 1
      this.scrollerHeightBar = '484px'
      this.scrollerHeightLine = '0px'
      this.scrollerHeightPie = '0px'
      this.$nextTick(() => {
        this.chartInstanceBar.dispose()
        this._initChartBar()
        this.chartInstanceLine.dispose()
        this._initChartLine()
        this.chartInstancePie.dispose()
        this._initChartPie()
      })
    },
    handlerLine() {
      this.curChart = 2
      this.scrollerHeightBar = '0px'
      this.scrollerHeightLine = '484px'
      this.scrollerHeightPie = '0px'
      this.$nextTick(() => {
        this.chartInstanceBar.dispose()
        this._initChartBar()
        this.chartInstanceLine.dispose()
        this._initChartLine()
        this.chartInstancePie.dispose()
        this._initChartPie()
      })
    },
    handlerPie() {
      this.curChart = 3
      this.scrollerHeightBar = '0px'
      this.scrollerHeightLine = '0px'
      this.scrollerHeightPie = '484px'
      this.$nextTick(() => {
        this.chartInstanceBar.dispose()
        this._initChartBar()
        this.chartInstanceLine.dispose()
        this._initChartLine()
        this.chartInstancePie.dispose()
        this._initChartPie()
      })
    },
    handlerHideTable() {
      this.isShowTable = !this.isShowTable;
    },

    handlerExportPDF() {
      if(this.curChart == 1) {
        this.$PDFSave(this.$refs.exportPdfBar, "媒体日报柱状图");
			} else if( this.curChart == 2) {
        this.$PDFSave(this.$refs.exportPdfLine, "媒体日报折线图");
			} else {
        this.$PDFSave(this.$refs.exportPdfPie, "媒体日报饼状图");
			}
      // this.$PDFSave(this.$refs.exportPdfBar, "媒体日报柱状图");
		}
  }
}
</script>

<style lang="scss" scoped>
	/* switch按钮样式 */
	::v-deep.switch {
		.el-switch__label {
			position: absolute;
			display: none;
			color: #fff !important;
		}
		
		/*打开时文字位置设置*/
		.el-switch__label--right {
			z-index: 1;
		}
		
		/* 调整打开时文字的显示位子 */
		.el-switch__label--right span {
			margin-right: 11px;
		}
		
		/*关闭时文字位置设置*/
		.el-switch__label--left {
			z-index: 1;
		}
		
		/* 调整关闭时文字的显示位子 */
		.el-switch__label--left span {
			margin-left: 11px;
		}
		
		/*显示文字*/
		.el-switch__label.is-active {
			display: block;
		}
		
		/* 调整按钮的宽度 */
		.el-switch__core, .el-switch__label {
			width: 55px !important;
			margin: 0;
		}
	}
</style>
